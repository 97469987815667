.spacetime-container {
    position: relative;
    height: 100vh;
    margin-left: -15px;
}

.spacetime-container .btn-sitting {
  width: auto !important;
  margin-left: 1rem;
  font-size: 1.5rem !important;
  color: black;
  background: rgba(0,123,255,0.25);
  padding: 0rem 2rem !important;
}

.spacetime-container table {
    width: 100%;
    border-color: grey;
    border: none;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
}

.spacetime-container table td .fixer {
  display: block;
  background: whitesmoke;
  border-radius: .3rem;
  margin: 1px;
  min-height: 3rem;
}

.spacetime-container table th {
  text-align: center;
  color: grey;
  transform: rotate(-90deg);
  white-space: nowrap;
}

@media (max-width: 768px) {
  .spacetime-container table th {
    padding-left: .5rem;
  }

  .spacetime-container table th.table-name {
    padding-left: 0rem;
  }

  .spacetime-container table th .table-capacity {
    display: none;
  }
}

@media (min-width: 768px) {
  .spacetime-container table th {
    font-size: 1.2rem;
    transform: rotate(-15deg);
    white-space: nowrap;
  }
}

.spacetime-container table th.table-name {
  transform: none;
}

.spacetime .block {
  display: table;
  background-color: rgba(132,222,122,1);
  border-radius: .3rem;
  position: absolute;
  overflow: hidden;
}

.spacetime .block .booking {
  display: table-cell;
  vertical-align: middle;
}

.spacetime .block .booking-covers {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  background: #333;
  color: white;
  border-radius: .3rem;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
}

.spacetime .block .booking-covers i {
  margin-right: 0rem;
}

.spacetime .block .booking-details {
  padding-left: .1rem;
  display: table-cell;
  vertical-align: middle;
  text-shadow: .1rem .1rem .3rem white;
  text-align: center;
  font-size: 1.2rem;
  white-space: nowrap;
}

.spacetime .origin-logo {
  padding: .2rem;
}

.spacetime .block .phone-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.spacetime .block .PhoneInput .PhoneInputCountry {
  float: left;
  margin: 0.7rem 0.4rem 0.7rem 1rem;
}

.spacetime .block .PhoneInput .PhoneInputInput {
  background-color: rgba(255,255,255, 0.5);
  width: 30%;
  max-width: 10rem;
  border: none !important;
}

.spacetime .PhoneInput .PhoneInputCountryIconImg {
  border: none;
}

.spacetime .block .PhoneInput .PhoneInputCountrySelectArrow {
  visibility: hidden;
}

.spacetime .drop-zone {
  display: table;
  border-radius: .3rem;
  position: absolute;
  overflow: hidden;
}

.spacetime .drop-zone div {
  width: 100%;
  height: 100%;
  opacity: .3
}

.spacetime .drop-zone .prohibited {
  background-color: red;
}

.spacetime .drop-zone .potential {
}

.spacetime .drop-zone .targeted {
  background-color: green;
}

/** old styles to potentially gleen from

.react-grid-item:not(.react-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
    border-radius: .5rem;
}

.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
    height: 100% !important;
}

.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}

.react-grid-item.cssTransforms {
    transition-property: transform;
}

.react-grid-item.resizing {
    z-index: 1;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
}

.react-grid-item.react-grid-placeholder {
    background: #000;
    opacity: 0.1;
    transition-duration: 100ms;
    z-index: 2;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item:hover > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

*/
