/* Maitred CSS */

@media (min-width: 700px) {
  #page-content-wrapper .maitred-form {
    max-width: 40rem;
    overflow-x: hidden;
  }
}

.container {
  padding-left: 0px;
  padding-right: 0px;
  max-width: none;
}

.establishment-logo {
  max-width: 100%;
  max-height: 10rem;
  margin: auto;
  width: auto;
  clear: both;
  display: block;
  margin-bottom: .5rem;
}

.maitred-form {
  text-align: center;
  padding: 1rem;
  margin: auto;
  background-position: center;
  background-size: cover;
  max-width: 50rem;
}

.maitred-form .insta-handle-input {
  max-width: 20rem;
  margin: auto;
}

.maitred-form .form-check {
  padding-left: 0rem !important;
}

.maitred-form .buff {
  display: block;
  width: 100%;
  height: 3rem;
}

.sparse-maitred {
  text-align: center;
}

.sparse-maitred h3 {
  margin: 2rem 0rem;
}

.sparse-maitred .sparse-button {
  margin-top: 3rem;
  min-width: 10rem;
}

.booking-request-view {
  text-align: center;
}

.booking-request-view h1 {
  font-size: 4rem;
  margin-top: 0.5rem;
}

.booking-request-view label {
  color: grey;
  margin: 1rem 1rem 0rem 0rem;
}

.error input, .error label {
  border: 2px solid red;
  background-color: pink !important;

  -webkit-animation: errorfade 2.0s forwards; /* for less modern browsers */
  animation: errorfade 2.0s forwards;
}

@keyframes errorfade {
  100% {background-color: #eee;}
}

/* Hide browser radios */
.maitred-form .form-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.react-datepicker__input-container {
  height: 100%;
}

/* Show radio labels */
.react-datepicker__input-container input,
.form-check select,
.form-check label {
  display: block;
  text-align: center;
  padding: 0.6rem 0rem;
  overflow-x: hidden;
}

.react-datepicker__input-container input {
  border-width: 0px;
  border-style: none;
}

/* On mouse-over */
.react-datepicker__input-container input:hover,
.form-check:hover input ~ label {
  cursor: pointer;
  transition: background-color 0.25s linear;
}

.maitred-form .flag-dropdown {
  width: 100% !important;
  opacity: 0;
}

.maitred-form .flag-dropdown.open {
  opacity: 1;
}

.maitred-form .country-selected .flag-dropdown {
  width: 4rem !important;
  opacity: 1 !important;
}

.maitred-form .react-tel-input .country-list {
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 80vh !important;
  max-height: 80vh !important;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.maitred-form .react-tel-input input {
  padding-left: 1rem;
}

.maitred-form .country-selected input {
  padding-left: 5rem;
}

.maitred-form .country-list {
  text-align: left;
  border-radius: 1rem !important;
  box-shadow: .1rem .1rem 1rem black !important;
}

.maitred-form .react-tel-input .selected-flag {
  width: 100% !important;
}

.maitred-form button {
  max-width: 16rem;
}

.maitred-form .flag-dropdown {
  width: 4rem;
}

.maitred-form .react-tel-input .flag {
  zoom: 1.5;
}

.formatted-number {
  position: absolute;
  left: 60%;
  margin-top: -1.5rem;
  font-size: 1rem !important;
  font-weight: bold;
  color: #666;
}

.time-row-loader {
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.time-row .col {
  min-width: 25%;
}

.maitred-form .nav {
  width: 100%;
}

.maitred-form .nav-item {
  width: 30%;
  height: auto;
  padding: 0.6rem !important;
  margin: 0rem .5rem;
}

.maitred-form .nav-item.active {
  font-weight: bold;
}

.maitred-form .tab-content {
  padding: 0.5rem !important;
  padding-top: 1rem !important;
  background-color: rgba(255, 255, 255, 0) !important;
  margin: auto;
}

.maitred-form .nav-tabs {
  border-bottom: none;
}

.maitred-notice {
  display: block;
  width: 100%;
  padding: 3rem 0rem 4rem 0rem;
  text-align: center;
  font-size: 1.5rem;
}

.alternative-time-offer {
  margin: 1rem;
}

.maitred-form .react-datepicker-wrapper {
  height: 100%;
  width: 100%;
}

.maitred-form .react-datepicker-wrapper ::-webkit-input-placeholder { /* Edge */
  color: black;
}

.maitred-form .react-datepicker-wrapper :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}

.maitred-form .react-datepicker-wrapper ::placeholder {
  color: black;
}

.maitred-form button[type='submit'] {
  margin-top: 1.5rem;
}

.maitred-form select,
.maitred-form .form-check-label,
.maitred-form .react-datepicker__input-container input {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%
}

.maitred-form .form-group input.checked,
.maitred-form .form-check input:checked ~ label {
  background-color: #ced4da;
}

.maitred-form .switch {
  margin: auto;
  width: 80vw;
}

.retrieve-booking-form {
  margin-top: 1rem;
}

.retrieve-booking-form button {
  width: 50%;
  margin-top: 1rem;
}

.retrieve-booking-form .form-control {
  font-size: 1.4rem !important;
  padding: 0.6rem;
}

.legal {
  font-size: 0.8em;
}

.modal-link i {
  margin: 0;
}

.modal-link {
  color: blue !important;
  text-decoration: underline !important;
}

.legal-modal .modal-header {
  font-size: 1.2em;
}

.legal-modal .modal-content {
  background-color: rgba(255, 255, 255, 0.9);
}
