.flag-dropdown {
    width: 100% !important;
    opacity: 0;
}

.flag-dropdown.open {
    opacity: 1;
}

.country-selected .flag-dropdown {
    width: 3.5rem !important;
    opacity: 1 !important;
}

.react-tel-input .country-list {
    position: fixed !important;
    top: 50%;
    left: 50%;
    min-height: 80vh !important;
    max-height: 80vh !important;
    transform: translate(-50%, -50%);
    text-align: left;
    border-radius: 1rem !important;
    box-shadow: .1rem .1rem 1rem black !important;
}

.react-tel-input input {
    padding-left: 1rem;
}

.country-selected input {
    padding-left: 5rem;
}

.react-tel-input .selected-flag {
    width: 100% !important;
}

.flag-dropdown {
    width: 4rem;
}
  
.react-tel-input .flag {
    zoom: 1.5;
}

.react-tel-input .form-control {
    padding-left: 1rem !important;
    font-size: inherit !important;
    height: inherit !important;
    width: inherit !important;
}

.react-tel-input.country-selected .form-control {
    padding-left: 4rem !important;
}