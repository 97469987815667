@import url(https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Domine&display=swap);
#resbee-logo {
  width: 10rem;
  margin: 1rem .5rem;
}

#resbee-logo-small {
  height: 4rem;
  margin: -0.5rem 0rem;
}

html, body {
  overflow-x: hidden;
}

hr {
  padding-bottom: 0.3rem;
}

h3 {
  display: block;
}

vr {
  background-color: #ced4da;
  margin: -0.5rem 1.5rem 0.5rem 1rem;
  color: #ced4da;
  width: 1px;
  vertical-align: middle;
}

form.error input, input.error {
  border-color: red;
}

.video-responsive{
    padding-bottom:56.25%;
    position:relative;
    overflow: hidden;
    border-radius: 1rem;
}

.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.ext-language-switch {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: block;
  width: 10rem;
  cursor: pointer;
  box-shadow: .1rem .1rem 1rem white;
  font-weight: bold;
  float: right;
  z-index: 100;
}

.buzz-container {
  margin: 0rem;
  padding: 1rem;
  min-height: 100vh;
  font-family: "Domine", serif;
}

.buzz-header {
  text-align: center;
  display: block;
  padding-top: 2rem;
  padding-bottom: 2vh;
  font-size: 3rem;
  text-shadow: .2rem .2rem 0rem white;
}

.buzz-body {
  overflow: hidden;
  font-size: 1.3rem;
  max-width: 60rem !important;
}

.buzz-body ul {
  list-style: none;
}

.buzz-body .row {
  margin-top: 2rem;
}

.buzz-body .row div:nth-child(1) {
  text-align: center;
  max-width: 4rem;
}

.buzz-body .row img {
  vertical-align: middle;
  display: inline-block;
  width: 5rem;
  margin: -2rem 0rem 1rem 0rem;
}

.buzz-body ul span {
  display: inline-block;
  width: 80%;
}

.buzz-body a {
  margin: 1rem;
  color: white !important;
  display: inline-block;
  min-width: 10rem;
}

.buzz-footer {
  display: block;
  padding-top: 1rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin: auto;
  color: grey;
  font-size: 0.8rem;
}

.button-container {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

.buzz-bee {
  width: 10rem;
  margin: -5vw 3vw 0vw 2vw;
}

.loading {
  display: block;
  min-width: 10rem;
  margin: auto;
  margin-top: 10rem;
  margin-bottom: 10rem;
  text-align: center;
  height: auto;
}

.loading.inline {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.loading-maitred {
  width: 50%;
  margin: 20vh auto;
}

.error-message {
  color: red;
  display: block;
  padding-bottom: 1em;
}

.calendar-date {
  display: inline-block;
  margin-left: 0.5rem;
  font-style: italic;
  color: blue;
}

.resbee-title {
  font-family: 'Grand Hotel', cursive;
  font-size: 8rem;
  display: block;
  margin: -2rem 0;
  color: white;
  text-shadow: 1px 1px 3px black;
}

.big-script {
  font-family: 'Grand Hotel', cursive;
  font-size: 5rem;
  display: block;
  margin-top: 2.5rem;
  width: 100%;
  text-align: center;
}

.rt-emphasise-cell {
  font-size: 1.5rem;
}

.container-fluid {
  padding-top: 15px;
  padding-bottom: 15px;
}

.new-booking-container {
  margin: -15px;
}

#header-arrow-wrapper {
  position: absolute;
  right: 0;
  display: block;
  width: 20%;
  height: 100px;
  text-align: right;
}

#header .new-bee {
  position: absolute;
  z-index: 10;
  width: 6rem;
  left: -8rem;
  top: 1.2rem;
  animation:head-arrow 1s;
  -moz-animation:head-arrow 1s; /* Firefox */
  -webkit-animation:head-arrow 1s; /* Safari and Chrome */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  visibility: visible;
  cursor: pointer;
}

#header .dropdown-menu {
  padding: 0;
  box-shadow: -.1rem .1rem 1rem grey;
  padding-top: .2rem;
  border-radius: 0.5rem;
}

#header .dropdown-menu button {
  font-size: 1.5rem;
  border-bottom: 1px solid rgba(0,0,0,.15);
}

#header .dropdown-menu > button {
  padding: 0.5rem 2rem 0.5rem 1rem;
}

#sidebar-wrapper .new-bee {
  position: absolute;
  z-index: 10;
  top: -3rem;
  display: none;
  width: 8rem;
  left: 7rem;
  animation:side-arrow 2s;
  -moz-animation:side-arrow 2s; /* Firefox */
  -webkit-animation:side-arrow 2s; /* Safari and Chrome */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

#sidebar-wrapper .new-bee-chime {
  visibility: hidden;
  animation:chime-bee 2s;
  -moz-animation:chime-bee 2s; /* Firefox */
  -webkit-animation:chime-bee 2s; /* Safari and Chrome */
}

@keyframes head-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}
@-webkit-keyframes head-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}

@keyframes side-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}
@-webkit-keyframes side-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}

@keyframes chime-bee {
  0% {visibility: visible; transform: rotate(-10deg) scaleX(-1); margin-left: 80vw; margin-top: 20vh; width: 80rem;}
  50% {visibility: visible; transform: rotate(20deg) scaleX(-1)}
  100% {visibility: visible; transform: rotate(-10deg) scaleX(-1); width: 0rem; margin-top: 3rem;}
}
@-webkit-keyframes chime-bee {
  0% {visibility: visible; transform: rotate(-10deg) scaleX(-1); margin-left: 80vw; margin-top: 20vh; width: 80rem;}
  50% {visibility: visible; transform: rotate(20deg) scaleX(-1)}
  100% {visibility: visible; transform: rotate(-10deg) scaleX(-1); width: 0rem; margin-top: 3rem;}
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -11rem;
  transition: margin .25s ease-out;
}

#sidebar-wrapper a.selected {
  color: white;
  border-bottom: none;
  background: #007bff !important;
}

#sidebar-wrapper .list-group {
  width: 11rem;
  cursor: pointer;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#header {
  margin-top: 0;
}

.customer-popup .text-nationality {
  font-weight: bold;
  font-style: italic;
  margin-top: -1.5rem;
  margin-bottom: -.8rem;
  margin-left: 0rem;
  text-align: center;
}

@media (min-width: 768px) {
  #header {
    margin-top: -5em;
  }

  #header .new-bee {
    visibility: hidden;
  }

  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -11rem;
  }

  #sidebar-wrapper .new-bee {
    display: block;
  }

  .buzz-header {
    font-size: 6vw;
  }

  .customer-popup .text-nationality {
    text-align: left;
    margin-left: 1rem;
  }
}

#sidebar-footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 11rem;
  padding: 1rem;
  display: table-cell;
  vertical-align: bottom;
}

#dropdown-footer {
  padding: 0.5em;
  width: 100%;
  text-align: center;
}

.center-page-wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.center-page {
  text-align: center;
  height: 80vh;
  display: table-cell;
  vertical-align: middle;
  font-size: 2rem;
  color: #333;
  padding: 1rem;
}

.success-tick {
  width: 20rem;
  margin: auto;
}

.page-notice {
  text-align: center;
  overflow-x: hidden;
  width: 100%;
}

.page-notice img {
  position: relative;
  width: 8rem;
  transform: scaleX(-1);
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.register-form, .reset-form {
  max-width: 40rem;
}

.register-form .btn-register {
  margin-top: 1rem;
}

.external-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
  background-color: #ccc;
  padding: 1rem;
}

.external-box {
  height: auto;
  min-height: 80vh;
  max-width: 40rem;
  text-align: center;
  margin: auto;
}

.splash, .mock-handheld-device {
  display: block;
  vertical-align: center;
  border: 1px solid black;
  box-shadow: 0.2rem 0.2rem 0.5rem;
  border-radius: 0.5rem;
  background-color:rgba(255, 255, 255, 0.6);
  overflow: hidden;
}

.mock-handheld-device {
  margin: 1rem;
}

.splash .nav-item {
  width: 33%;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  border-right: 1px solid #999 !important;
  border-bottom: 1px solid #999 !important;
}

.nav-item:hover {
  color: black;
  background-color:rgba(255, 255, 255, 0.4);
  transition: background-color 0.25s linear;
}

.nav-item.active {
  background-color: white;
  color:black !important;
  border-bottom: 1px solid white !important;
}

.tab-content {
  padding: 1rem 2rem;
  background-color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  text-align: left;
}

.tab-content button, .tab-content [class^=styles_particles] {
  margin: auto;
}

.origin-logo {
  width: 3rem;
  display: block;
  float: right;
}

.form-error {
  color: red;
  display: block;
  padding-bottom: 1em;
  margin-top: -1em;
}

.StripeElement, .PhoneInputInput {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
  border-radius: .25rem;
}

.PhoneInputInput {
  padding: .375rem .75rem !important;
}

.PhoneInputCountryIconImg {
  width: 230% !important;
  height: 230% !important;
  margin: -0.7em 0em 0em -0.6em !important;
  border: 1px solid grey;
  border-radius: 0.5rem 0em 0em 0.5em;
}

.export-table {
  margin-left: 1rem;
}

.export-table i {
  margin: 0rem;
}

.requests-table {
  text-align: center;
  margin-bottom: 1.5rem;
}

.requests-table th,
.requests-table td {
  background-color: #FCF6BA;
  border: .2rem solid white !important;
}

.requests-table tr:hover td,
.requests-table tr.selected td {
  cursor: pointer;
  background-color: rgba(0,123,255,0.5);
  transition: background-color 0.25s linear;
}

.requests-table th {
  background-color: gold;
}

.requests-table tr {
  height: 4rem;
}

.requests-table .request-options button {
  margin: 0rem 0.5rem;
}

.requests-table .text-nationality {
  display: block;
  text-align: center;
  padding: 0rem 0.5rem;
  position: relative;
  background-color:#FCF6BA;
  margin: auto;
  margin-top: -1.5rem;
  border-radius: 0.5rem;
}

.requests-table .cell-nationality .PhoneInput {
  margin: auto;
  font-size: 1.5rem;
  width: 3rem;
}

.requests-table .cell-nationality .PhoneInput .PhoneInputCountryIconImg {
  border-radius: 0.5em !important;
  border: none;
}

.requests-table .cell-nationality .PhoneInput input,
.requests-table .cell-nationality .PhoneInput .PhoneInputCountrySelectArrow {
  display: none;
}

.customer-popup {
  text-align: center;
}

.customer-popup .modal-body {
  min-height: 25rem;
  padding: 0rem;
}

.customer-popup .customer-section {
  padding: 1rem;
  border-bottom: 1px solid rgba(0,0,0,.2);
}

.customer-popup .customer-subsection {
  display: inline-block;
  width: 50%;
}

.customer-popup .customer-subsection-mob .PhoneInput {
  margin: auto;
  width: 15rem;
}

.customer-popup .mobile-booking-row {
  font-size: 1.4rem;
}

.customer-popup .mobile-booking-row .origin-logo {
  display: inline-block;
  float: none;
  position: relative;
  top: -.2rem;
  width: 2.5rem;
}

.customer-popup i {
  font-size: 1.8rem;
  margin: auto;
}

.customer-popup .customer-subsection-mob {
  display: block;
  margin: auto;
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: center;
}

.customer-popup .instagram-logo {
  width: 3rem;
  margin-top: -0.6rem;
  margin-right: 0.5rem;
  margin-left: -2rem;
}

.customer-popup .instagram-handle {
  color: #495057;
  top: -.2rem;
  position: relative;
  display: inline-block;
  font-size: 1.2rem;
}

.customer-name {
  font-size: 2rem;
  padding-top: 1rem;
}

.customer-popup .customer-visits {
  display: flexbox;
  width: 90%;
  margin: auto;
  margin-top: 0.6rem;
}

.customer-popup .customer-visits td {
  padding: 0.3rem;
}

.customer-popup .customer-visits td:first-child {
  font-weight: bold;
}

.customer-popup .btn-close {
  font-size: 2rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: block;
  position: absolute;
  right: -1rem;
  top: -1rem;
  border: 1px solid rgba(0,0,0,.2);
  z-index: 1000;
}

.box-row .header-text,
.options-popup .header-text {
  font-size: 1.5rem;
}

.box-row i,
.options-popup .modal-header i {
  margin: 0.4rem 0.4rem 0rem 1rem;
}

.options-popup .modal-body {
  text-align: center;
}

.options-popup .modal-body button {
  width: 100%;
}

.options-popup .modal-content h3 {
  display: block;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.options-popup .modal-content .offer-times {
  margin-top: 0rem;
}

.options-popup .modal-content .col {
  min-width: 25%;
}

.options-popup .modal-content .form-check {
  padding: 0rem;
}

.options-popup .modal-content .tab-content {
  padding: 1rem 0rem;
}

.options-popup .modal-content input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.options-popup .modal-content label {
  margin: 0rem .3rem;
  background-color: whitesmoke;
  border-radius: 0.3rem;
}

.options-popup .modal-content input:checked ~ label {
  margin: 0rem .3rem;
  background-color: #007bff;
  color: white;
}

.customer-table .PhoneInput .PhoneInputCountryIcon--border {
  background-color: none !important;
  box-shadow: none !important;
}

.customer-table .PhoneInput .PhoneInputCountryIconImg {
  margin-left: 0em !important;
  margin-right: 1em !important;
  border: none !important;
  border-radius: 0.5em;
}

.customer-table .PhoneInput .PhoneInputCountrySelectArrow {
  display: none;
}

.customer-table .PhoneInput .PhoneInputInput {
  margin-left: 1.2rem;
  border: none !important;
  background: none !important;
}

.customer-table {
  text-align: center;
}

.customer-table td {
  white-space: nowrap;
}

.customer-table tr:hover {
  cursor: pointer;
  background-color: rgba(0,123,255,0.5) !important;
  transition: background-color 0.25s linear;
}

.customer-table thead tr:hover {
  background-color: white !important;
}

.filter-form {
  display: inline-block;
  margin-left: 0.5rem;
  width: 50%;
}

.filter-form::-webkit-input-placeholder {
  font-style: italic;
}

.filter-form:-ms-input-placeholder {
  font-style: italic;
}

.filter-form::-ms-input-placeholder {
  font-style: italic;
}

.filter-form::placeholder {
  font-style: italic;
}

.stubby-input, .super-stubby-input {
  display: inline-block;
  text-align: center;
}

.super-stubby-input {
  width: 3rem !important;
}

.stubby-input {
  width: 6rem;
}

.overview-page {
  overflow-x: scroll;
}

.overview-page h3 {
  float: left;
  margin: 0.8rem;
}

.mobile-table .box-row {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  background: linear-gradient(180deg, #ffffff 0%, #dee2e6 100%);
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid grey;
  overflow: hidden;
}

.mobile-table .box-row.unconfirmed {
  background: linear-gradient(180deg, #ffffff 0%, #faf9c4 100%);
}

.mobile-table .header-text {
  padding: .5rem 0;
  font-size: 1.5rem;
  border-bottom: 1px solid grey;
}

.mobile-table .box-body {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  border-bottom: 1px solid grey;
}

.mobile-table .box-body .PhoneInput {
  font-size: 1rem;
  margin-right: 1rem;
  clear: both;
  max-width: 15rem;
  margin: auto;
}

.mobile-table .box-body .additional-info {
  display: block;
  margin-top: 1rem;
  font-size: 1rem;
  font-style: italic;
}

.mobile-table .box-footer {
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  font-style: italic;
}

.mobile-table .box-footer .soft-label {
  margin-right: 1rem;
  color: grey;
}

.mobile-table .box-footer .barloader-container {
  display: block;
  width: 50%;
  float: left;
  margin: 0.7rem 5rem;
}

.mobile-table .box-footer .barloader-container div {
  width: 100%;
}

.mobile-table .box-footer .origin-logo {
  width: 2rem;
  margin-top: -0.2rem;
  margin-left: 0.5rem;
  display: inline;
  float: none;
}

.mobile-table .box-row .PhoneInputCountrySelectArrow {
  visibility: hidden;
}

.spacetime-container .date-navigator,
.overview-page .date-navigator {
  float: left;
  display: block;
  height: auto;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  margin-left: 1rem;
  margin-bottom: 0.8rem;
}

@media (max-width: 768px) {
  .spacetime-container .date-navigator{
    zoom: 0.6;
    margin-bottom: 3rem;
  }
}

.spacetime-container .date-navigator button,
.overview-page .date-navigator button {
  width: 4.5rem;
  height: 3.5rem;
  font-size: 2rem;
  padding-top: -2rem;
  line-height: 1rem;
}

.spacetime-container .react-datepicker__input-container input,
.overview-page .react-datepicker__input-container input {
  font-size: 1.5rem;
  background-color: rgba(0,123,255,0.25);
  width: 12rem;
  margin: 0rem -0.3rem;
}

.spacetime-container .react-datepicker__input-container input:hover,
.overview-page .react-datepicker__input-container input:hover {
  background-color: rgba(0,123,255,0.5);
}

.settings-page h3 {
  text-align: center;
}

.settings-page .stripe-account-connected {
  text-align: center;
  display: inline-block;
  background-color: #7a73ff;
  padding: .5rem 1rem;
  border-radius: 0.3rem;
  color: white;
}

.settings-page .stripe-account-connected:hover {
  background-color: #635bff;
  cursor: pointer;
  text-decoration: none;
}

.settings-page .tier-bee {
  position: absolute;
  width: 8rem;
  top: -6rem;
  z-index: 10;
}

.settings-page .align-center {
  text-align: center;
}

.settings-page .SMS-infobox {
  display: block;
  width: 90%;
  margin: auto;
  margin-bottom: 4rem;
}

.settings-page .card {
  display: inline-flex;
  margin: 0.5rem;
  min-width: 8rem;
  width: 22%;
  text-align: center;
  border-top: 0.2rem solid rgb(0,123,255);
}

.settings-page .card .list-group-item:nth-child(1) {
}

.settings-page .card .list-group-item:nth-child(2) {
  font-size: 1.5rem;
}

.settings-page .card .list-group-item:nth-child(3) {
  font-style: italic;
}

.settings-page .dummy-booking {
  display: block;
  background-color: whitesmoke;
  margin: auto;
  padding: 1rem;
}

.settings-page .tab-content {
  border: 1px solid #dee2e6;
  border-radius: 0rem 0rem .3rem .3rem;
  border-top: none;
}

.settings-page .weekdays-wrapper {
  border: none;
}

.settings-page .weekday-sittings.active.show {
  display: inline-flex;
}

.settings-page .weekday-opening-hours a {
  text-align: center;
}

.settings-page .button-container button {
  margin: 0rem 0.5rem;
}

.settings-page .update-settings {
  margin: 1rem auto;
  width: 10rem;
}

.settings-page .comms-mockup .comms-message {
  display: block;
  border-radius: 0rem 1rem 1rem 1rem;
  background-color: #007bff;
  padding: 1rem;
  margin: 0rem 1rem;
  margin-bottom: 1rem;
  color: white;
}

.settings-page .comms-mockup .comms-charcount-inlimit {
  display: block;
  margin: -0.8rem 1rem 0rem 0rem;
  float: right;
  font-size: 0.8rem;
  color: grey;
}

.settings-page .comms-mockup .comms-charcount-exceeded {
  display: block;
  margin: -0.8rem 1rem 0rem 0rem;
  float: right;
  font-size: 0.8rem;
  font-weight: bold;
  color: red;
}

.settings-page .comms-mockup label {
  padding: 0rem 1rem;
  margin-top: 1rem;
  font-weight: bold;
}

table thead th {
  vertical-align: middle !important;
}

.table td {
  vertical-align: middle;
}

.weekday-label {
  display: inline-flex;
  font-weight: bold;
  font-size: 1.2em;
  width: 7rem;
  text-align: right;
}

.settings-page .sittings {
  display: inline-flex;
}

.settings-page .sitting .form-control {
  width: 6rem;
}

.settings-page .sitting .form-control:nth-child(1) {
  width: 100%;
}

.settings-page .sitting {
  background-color: rgba(0, 123, 255, 0.1);
  border: 1px solid #ced4da;
  margin: 0rem 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  min-width: 18rem;
}

.settings-page .unsaved {
  background-color: whitesmoke;
}

.settings-page .sitting .sitting-name {
  margin-top: -2rem;
  text-align: center;
  font-weight: bold;
}

.settings-page .sitting .form-label {
  padding: 0.5rem;
  text-align: right;
}

.settings-page .maitred-table-plan {
  max-width: 40rem;
  text-align: center;
}

.settings-page .maitred-table-plan .col {
  margin: 0.2rem;
}

.settings-page .maitred-table-plan button {
  margin: 0.2rem;
  width: 10rem;
}

.settings-page .spacetime-toggle {
  min-width: 15rem !important;
  margin-bottom: 1rem;
  margin-left: -1rem;
  white-space: nowrap;
}

.pagination {
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: center;
}

.weekdays-form .form-group {
  background-color: whitesmoke;
}

.maitred-form-setup .form-label {
  padding: 0.5rem 1rem;
  text-align: right;
}

.maitred-form-setup .form-control {
  font-family: 'Courier', sans-serif;
}

i {
  margin-right: 1em;
  width: 1.2em;
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.flag-dropdown {
    width: 100% !important;
    opacity: 0;
}

.flag-dropdown.open {
    opacity: 1;
}

.country-selected .flag-dropdown {
    width: 3.5rem !important;
    opacity: 1 !important;
}

.react-tel-input .country-list {
    position: fixed !important;
    top: 50%;
    left: 50%;
    min-height: 80vh !important;
    max-height: 80vh !important;
    transform: translate(-50%, -50%);
    text-align: left;
    border-radius: 1rem !important;
    box-shadow: .1rem .1rem 1rem black !important;
}

.react-tel-input input {
    padding-left: 1rem;
}

.country-selected input {
    padding-left: 5rem;
}

.react-tel-input .selected-flag {
    width: 100% !important;
}

.flag-dropdown {
    width: 4rem;
}
  
.react-tel-input .flag {
    zoom: 1.5;
}

.react-tel-input .form-control {
    padding-left: 1rem !important;
    font-size: inherit !important;
    height: inherit !important;
    width: inherit !important;
}

.react-tel-input.country-selected .form-control {
    padding-left: 4rem !important;
}
/* Maitred CSS */

@media (min-width: 700px) {
  #page-content-wrapper .maitred-form {
    max-width: 40rem;
    overflow-x: hidden;
  }
}

.container {
  padding-left: 0px;
  padding-right: 0px;
  max-width: none;
}

.establishment-logo {
  max-width: 100%;
  max-height: 10rem;
  margin: auto;
  width: auto;
  clear: both;
  display: block;
  margin-bottom: .5rem;
}

.maitred-form {
  text-align: center;
  padding: 1rem;
  margin: auto;
  background-position: center;
  background-size: cover;
  max-width: 50rem;
}

.maitred-form .insta-handle-input {
  max-width: 20rem;
  margin: auto;
}

.maitred-form .form-check {
  padding-left: 0rem !important;
}

.maitred-form .buff {
  display: block;
  width: 100%;
  height: 3rem;
}

.sparse-maitred {
  text-align: center;
}

.sparse-maitred h3 {
  margin: 2rem 0rem;
}

.sparse-maitred .sparse-button {
  margin-top: 3rem;
  min-width: 10rem;
}

.booking-request-view {
  text-align: center;
}

.booking-request-view h1 {
  font-size: 4rem;
  margin-top: 0.5rem;
}

.booking-request-view label {
  color: grey;
  margin: 1rem 1rem 0rem 0rem;
}

.error input, .error label {
  border: 2px solid red;
  background-color: pink !important;

  -webkit-animation: errorfade 2.0s forwards; /* for less modern browsers */
  animation: errorfade 2.0s forwards;
}

@-webkit-keyframes errorfade {
  100% {background-color: #eee;}
}

@keyframes errorfade {
  100% {background-color: #eee;}
}

/* Hide browser radios */
.maitred-form .form-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.react-datepicker__input-container {
  height: 100%;
}

/* Show radio labels */
.react-datepicker__input-container input,
.form-check select,
.form-check label {
  display: block;
  text-align: center;
  padding: 0.6rem 0rem;
  overflow-x: hidden;
}

.react-datepicker__input-container input {
  border-width: 0px;
  border-style: none;
}

/* On mouse-over */
.react-datepicker__input-container input:hover,
.form-check:hover input ~ label {
  cursor: pointer;
  transition: background-color 0.25s linear;
}

.maitred-form .flag-dropdown {
  width: 100% !important;
  opacity: 0;
}

.maitred-form .flag-dropdown.open {
  opacity: 1;
}

.maitred-form .country-selected .flag-dropdown {
  width: 4rem !important;
  opacity: 1 !important;
}

.maitred-form .react-tel-input .country-list {
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 80vh !important;
  max-height: 80vh !important;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.maitred-form .react-tel-input input {
  padding-left: 1rem;
}

.maitred-form .country-selected input {
  padding-left: 5rem;
}

.maitred-form .country-list {
  text-align: left;
  border-radius: 1rem !important;
  box-shadow: .1rem .1rem 1rem black !important;
}

.maitred-form .react-tel-input .selected-flag {
  width: 100% !important;
}

.maitred-form button {
  max-width: 16rem;
}

.maitred-form .flag-dropdown {
  width: 4rem;
}

.maitred-form .react-tel-input .flag {
  zoom: 1.5;
}

.formatted-number {
  position: absolute;
  left: 60%;
  margin-top: -1.5rem;
  font-size: 1rem !important;
  font-weight: bold;
  color: #666;
}

.time-row-loader {
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.time-row .col {
  min-width: 25%;
}

.maitred-form .nav {
  width: 100%;
}

.maitred-form .nav-item {
  width: 30%;
  height: auto;
  padding: 0.6rem !important;
  margin: 0rem .5rem;
}

.maitred-form .nav-item.active {
  font-weight: bold;
}

.maitred-form .tab-content {
  padding: 0.5rem !important;
  padding-top: 1rem !important;
  background-color: rgba(255, 255, 255, 0) !important;
  margin: auto;
}

.maitred-form .nav-tabs {
  border-bottom: none;
}

.maitred-notice {
  display: block;
  width: 100%;
  padding: 3rem 0rem 4rem 0rem;
  text-align: center;
  font-size: 1.5rem;
}

.alternative-time-offer {
  margin: 1rem;
}

.maitred-form .react-datepicker-wrapper {
  height: 100%;
  width: 100%;
}

.maitred-form .react-datepicker-wrapper ::-webkit-input-placeholder { /* Edge */
  color: black;
}

.maitred-form .react-datepicker-wrapper :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}

.maitred-form .react-datepicker-wrapper ::-ms-input-placeholder {
  color: black;
}

.maitred-form .react-datepicker-wrapper ::placeholder {
  color: black;
}

.maitred-form button[type='submit'] {
  margin-top: 1.5rem;
}

.maitred-form select,
.maitred-form .form-check-label,
.maitred-form .react-datepicker__input-container input {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%
}

.maitred-form .form-group input.checked,
.maitred-form .form-check input:checked ~ label {
  background-color: #ced4da;
}

.maitred-form .switch {
  margin: auto;
  width: 80vw;
}

.retrieve-booking-form {
  margin-top: 1rem;
}

.retrieve-booking-form button {
  width: 50%;
  margin-top: 1rem;
}

.retrieve-booking-form .form-control {
  font-size: 1.4rem !important;
  padding: 0.6rem;
}

.legal {
  font-size: 0.8em;
}

.modal-link i {
  margin: 0;
}

.modal-link {
  color: blue !important;
  text-decoration: underline !important;
}

.legal-modal .modal-header {
  font-size: 1.2em;
}

.legal-modal .modal-content {
  background-color: rgba(255, 255, 255, 0.9);
}

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.spacetime-container {
    position: relative;
    height: 100vh;
    margin-left: -15px;
}

.spacetime-container .btn-sitting {
  width: auto !important;
  margin-left: 1rem;
  font-size: 1.5rem !important;
  color: black;
  background: rgba(0,123,255,0.25);
  padding: 0rem 2rem !important;
}

.spacetime-container table {
    width: 100%;
    border-color: grey;
    border: none;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
}

.spacetime-container table td .fixer {
  display: block;
  background: whitesmoke;
  border-radius: .3rem;
  margin: 1px;
  min-height: 3rem;
}

.spacetime-container table th {
  text-align: center;
  color: grey;
  transform: rotate(-90deg);
  white-space: nowrap;
}

@media (max-width: 768px) {
  .spacetime-container table th {
    padding-left: .5rem;
  }

  .spacetime-container table th.table-name {
    padding-left: 0rem;
  }

  .spacetime-container table th .table-capacity {
    display: none;
  }
}

@media (min-width: 768px) {
  .spacetime-container table th {
    font-size: 1.2rem;
    transform: rotate(-15deg);
    white-space: nowrap;
  }
}

.spacetime-container table th.table-name {
  transform: none;
}

.spacetime .block {
  display: table;
  background-color: rgba(132,222,122,1);
  border-radius: .3rem;
  position: absolute;
  overflow: hidden;
}

.spacetime .block .booking {
  display: table-cell;
  vertical-align: middle;
}

.spacetime .block .booking-covers {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  background: #333;
  color: white;
  border-radius: .3rem;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
}

.spacetime .block .booking-covers i {
  margin-right: 0rem;
}

.spacetime .block .booking-details {
  padding-left: .1rem;
  display: table-cell;
  vertical-align: middle;
  text-shadow: .1rem .1rem .3rem white;
  text-align: center;
  font-size: 1.2rem;
  white-space: nowrap;
}

.spacetime .origin-logo {
  padding: .2rem;
}

.spacetime .block .phone-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.spacetime .block .PhoneInput .PhoneInputCountry {
  float: left;
  margin: 0.7rem 0.4rem 0.7rem 1rem;
}

.spacetime .block .PhoneInput .PhoneInputInput {
  background-color: rgba(255,255,255, 0.5);
  width: 30%;
  max-width: 10rem;
  border: none !important;
}

.spacetime .PhoneInput .PhoneInputCountryIconImg {
  border: none;
}

.spacetime .block .PhoneInput .PhoneInputCountrySelectArrow {
  visibility: hidden;
}

.spacetime .drop-zone {
  display: table;
  border-radius: .3rem;
  position: absolute;
  overflow: hidden;
}

.spacetime .drop-zone div {
  width: 100%;
  height: 100%;
  opacity: .3
}

.spacetime .drop-zone .prohibited {
  background-color: red;
}

.spacetime .drop-zone .potential {
}

.spacetime .drop-zone .targeted {
  background-color: green;
}

/** old styles to potentially gleen from

.react-grid-item:not(.react-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
    border-radius: .5rem;
}

.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
    height: 100% !important;
}

.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}

.react-grid-item.cssTransforms {
    transition-property: transform;
}

.react-grid-item.resizing {
    z-index: 1;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
}

.react-grid-item.react-grid-placeholder {
    background: #000;
    opacity: 0.1;
    transition-duration: 100ms;
    z-index: 2;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item:hover > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

*/

.stripe-connect {
    background: #635bff;
    display: inline-block;
    height: 38px;
    text-decoration: none;
    width: 180px;
    margin-left: 1rem;
  
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
  
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  
    -webkit-font-smoothing: antialiased;
  }
  
  .stripe-connect span {
    color: #ffffff;
    display: block;
    font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    padding: 11px 0px 0px 24px;
    position: relative;
    text-align: left;
  }
  
  .stripe-connect:hover {
    background: #7a73ff;
  }
  
  .stripe-connect.slate {
    background: #0a2540;
  }
  
  .stripe-connect.slate:hover {
    background: #425466;
  }
  
  .stripe-connect.white {
    background: #ffffff;
  }
  
  .stripe-connect.white span {
    color: #0a2540;
  }
  
  .stripe-connect.white:hover {
    background: #f6f9fc;
  }
  
  .stripe-connect span::after {
    background-repeat: no-repeat;
    background-size: 49.58px;
    content: "";
    height: 20px;
    left: 62%;
    position: absolute;
    top: 28.95%;
    width: 49.58px;
  }
  
  /* Logos */
  .stripe-connect span::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
  }
  
  .stripe-connect.white span::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
  }
