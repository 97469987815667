@import url('https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Domine&display=swap');

#resbee-logo {
  width: 10rem;
  margin: 1rem .5rem;
}

#resbee-logo-small {
  height: 4rem;
  margin: -0.5rem 0rem;
}

html, body {
  overflow-x: hidden;
}

hr {
  padding-bottom: 0.3rem;
}

h3 {
  display: block;
}

vr {
  background-color: #ced4da;
  margin: -0.5rem 1.5rem 0.5rem 1rem;
  color: #ced4da;
  width: 1px;
  vertical-align: middle;
}

form.error input, input.error {
  border-color: red;
}

.video-responsive{
    padding-bottom:56.25%;
    position:relative;
    overflow: hidden;
    border-radius: 1rem;
}

.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.ext-language-switch {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: block;
  width: 10rem;
  cursor: pointer;
  box-shadow: .1rem .1rem 1rem white;
  font-weight: bold;
  float: right;
  z-index: 100;
}

.buzz-container {
  margin: 0rem;
  padding: 1rem;
  min-height: 100vh;
  font-family: "Domine", serif;
}

.buzz-header {
  text-align: center;
  display: block;
  padding-top: 2rem;
  padding-bottom: 2vh;
  font-size: 3rem;
  text-shadow: .2rem .2rem 0rem white;
}

.buzz-body {
  overflow: hidden;
  font-size: 1.3rem;
  max-width: 60rem !important;
}

.buzz-body ul {
  list-style: none;
}

.buzz-body .row {
  margin-top: 2rem;
}

.buzz-body .row div:nth-child(1) {
  text-align: center;
  max-width: 4rem;
}

.buzz-body .row img {
  vertical-align: middle;
  display: inline-block;
  width: 5rem;
  margin: -2rem 0rem 1rem 0rem;
}

.buzz-body ul span {
  display: inline-block;
  width: 80%;
}

.buzz-body a {
  margin: 1rem;
  color: white !important;
  display: inline-block;
  min-width: 10rem;
}

.buzz-footer {
  display: block;
  padding-top: 1rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin: auto;
  color: grey;
  font-size: 0.8rem;
}

.button-container {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

.buzz-bee {
  width: 10rem;
  margin: -5vw 3vw 0vw 2vw;
}

.loading {
  display: block;
  min-width: 10rem;
  margin: auto;
  margin-top: 10rem;
  margin-bottom: 10rem;
  text-align: center;
  height: auto;
}

.loading.inline {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.loading-maitred {
  width: 50%;
  margin: 20vh auto;
}

.error-message {
  color: red;
  display: block;
  padding-bottom: 1em;
}

.calendar-date {
  display: inline-block;
  margin-left: 0.5rem;
  font-style: italic;
  color: blue;
}

.resbee-title {
  font-family: 'Grand Hotel', cursive;
  font-size: 8rem;
  display: block;
  margin: -2rem 0;
  color: white;
  text-shadow: 1px 1px 3px black;
}

.big-script {
  font-family: 'Grand Hotel', cursive;
  font-size: 5rem;
  display: block;
  margin-top: 2.5rem;
  width: 100%;
  text-align: center;
}

.rt-emphasise-cell {
  font-size: 1.5rem;
}

.container-fluid {
  padding-top: 15px;
  padding-bottom: 15px;
}

.new-booking-container {
  margin: -15px;
}

#header-arrow-wrapper {
  position: absolute;
  right: 0;
  display: block;
  width: 20%;
  height: 100px;
  text-align: right;
}

#header .new-bee {
  position: absolute;
  z-index: 10;
  width: 6rem;
  left: -8rem;
  top: 1.2rem;
  animation:head-arrow 1s;
  -moz-animation:head-arrow 1s; /* Firefox */
  -webkit-animation:head-arrow 1s; /* Safari and Chrome */
  animation-iteration-count: infinite;
  visibility: visible;
  cursor: pointer;
}

#header .dropdown-menu {
  padding: 0;
  box-shadow: -.1rem .1rem 1rem grey;
  padding-top: .2rem;
  border-radius: 0.5rem;
}

#header .dropdown-menu button {
  font-size: 1.5rem;
  border-bottom: 1px solid rgba(0,0,0,.15);
}

#header .dropdown-menu > button {
  padding: 0.5rem 2rem 0.5rem 1rem;
}

#sidebar-wrapper .new-bee {
  position: absolute;
  z-index: 10;
  top: -3rem;
  display: none;
  width: 8rem;
  left: 7rem;
  animation:side-arrow 2s;
  -moz-animation:side-arrow 2s; /* Firefox */
  -webkit-animation:side-arrow 2s; /* Safari and Chrome */
  animation-iteration-count: infinite;
}

#sidebar-wrapper .new-bee-chime {
  visibility: hidden;
  animation:chime-bee 2s;
  -moz-animation:chime-bee 2s; /* Firefox */
  -webkit-animation:chime-bee 2s; /* Safari and Chrome */
}

@keyframes head-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}
@-moz-keyframes head-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}
@-webkit-keyframes head-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}

@keyframes side-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}
@-moz-keyframes side-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}
@-webkit-keyframes side-arrow {
  0% {transform: rotate(-10deg)}
  50% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}

@keyframes chime-bee {
  0% {visibility: visible; transform: rotate(-10deg) scaleX(-1); margin-left: 80vw; margin-top: 20vh; width: 80rem;}
  50% {visibility: visible; transform: rotate(20deg) scaleX(-1)}
  100% {visibility: visible; transform: rotate(-10deg) scaleX(-1); width: 0rem; margin-top: 3rem;}
}
@-moz-keyframes chime-bee {
  0% {visibility: visible; transform: rotate(-10deg) scaleX(-1); margin-left: 80vw; margin-top: 20vh; width: 80rem;}
  50% {visibility: visible; transform: rotate(20deg) scaleX(-1)}
  100% {visibility: visible; transform: rotate(-10deg) scaleX(-1); width: 0rem; margin-top: 3rem;}
}
@-webkit-keyframes chime-bee {
  0% {visibility: visible; transform: rotate(-10deg) scaleX(-1); margin-left: 80vw; margin-top: 20vh; width: 80rem;}
  50% {visibility: visible; transform: rotate(20deg) scaleX(-1)}
  100% {visibility: visible; transform: rotate(-10deg) scaleX(-1); width: 0rem; margin-top: 3rem;}
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -11rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper a.selected {
  color: white;
  border-bottom: none;
  background: #007bff !important;
}

#sidebar-wrapper .list-group {
  width: 11rem;
  cursor: pointer;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#header {
  margin-top: 0;
}

.customer-popup .text-nationality {
  font-weight: bold;
  font-style: italic;
  margin-top: -1.5rem;
  margin-bottom: -.8rem;
  margin-left: 0rem;
  text-align: center;
}

@media (min-width: 768px) {
  #header {
    margin-top: -5em;
  }

  #header .new-bee {
    visibility: hidden;
  }

  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -11rem;
  }

  #sidebar-wrapper .new-bee {
    display: block;
  }

  .buzz-header {
    font-size: 6vw;
  }

  .customer-popup .text-nationality {
    text-align: left;
    margin-left: 1rem;
  }
}

#sidebar-footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 11rem;
  padding: 1rem;
  display: table-cell;
  vertical-align: bottom;
}

#dropdown-footer {
  padding: 0.5em;
  width: 100%;
  text-align: center;
}

.center-page-wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.center-page {
  text-align: center;
  height: 80vh;
  display: table-cell;
  vertical-align: middle;
  font-size: 2rem;
  color: #333;
  padding: 1rem;
}

.success-tick {
  width: 20rem;
  margin: auto;
}

.page-notice {
  text-align: center;
  overflow-x: hidden;
  width: 100%;
}

.page-notice img {
  position: relative;
  width: 8rem;
  transform: scaleX(-1);
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.register-form, .reset-form {
  max-width: 40rem;
}

.register-form .btn-register {
  margin-top: 1rem;
}

.external-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
  background-color: #ccc;
  padding: 1rem;
}

.external-box {
  height: auto;
  min-height: 80vh;
  max-width: 40rem;
  text-align: center;
  margin: auto;
}

.splash, .mock-handheld-device {
  display: block;
  vertical-align: center;
  border: 1px solid black;
  box-shadow: 0.2rem 0.2rem 0.5rem;
  border-radius: 0.5rem;
  background-color:rgba(255, 255, 255, 0.6);
  overflow: hidden;
}

.mock-handheld-device {
  margin: 1rem;
}

.splash .nav-item {
  width: 33%;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  border-right: 1px solid #999 !important;
  border-bottom: 1px solid #999 !important;
}

.nav-item:hover {
  color: black;
  background-color:rgba(255, 255, 255, 0.4);
  transition: background-color 0.25s linear;
}

.nav-item.active {
  background-color: white;
  color:black !important;
  border-bottom: 1px solid white !important;
}

.tab-content {
  padding: 1rem 2rem;
  background-color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  text-align: left;
}

.tab-content button, .tab-content [class^=styles_particles] {
  margin: auto;
}

.origin-logo {
  width: 3rem;
  display: block;
  float: right;
}

.form-error {
  color: red;
  display: block;
  padding-bottom: 1em;
  margin-top: -1em;
}

.StripeElement, .PhoneInputInput {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
  border-radius: .25rem;
}

.PhoneInputInput {
  padding: .375rem .75rem !important;
}

.PhoneInputCountryIconImg {
  width: 230% !important;
  height: 230% !important;
  margin: -0.7em 0em 0em -0.6em !important;
  border: 1px solid grey;
  border-radius: 0.5rem 0em 0em 0.5em;
}

.export-table {
  margin-left: 1rem;
}

.export-table i {
  margin: 0rem;
}

.requests-table {
  text-align: center;
  margin-bottom: 1.5rem;
}

.requests-table th,
.requests-table td {
  background-color: #FCF6BA;
  border: .2rem solid white !important;
}

.requests-table tr:hover td,
.requests-table tr.selected td {
  cursor: pointer;
  background-color: rgba(0,123,255,0.5);
  transition: background-color 0.25s linear;
}

.requests-table th {
  background-color: gold;
}

.requests-table tr {
  height: 4rem;
}

.requests-table .request-options button {
  margin: 0rem 0.5rem;
}

.requests-table .text-nationality {
  display: block;
  text-align: center;
  padding: 0rem 0.5rem;
  position: relative;
  background-color:#FCF6BA;
  margin: auto;
  margin-top: -1.5rem;
  border-radius: 0.5rem;
}

.requests-table .cell-nationality .PhoneInput {
  margin: auto;
  font-size: 1.5rem;
  width: 3rem;
}

.requests-table .cell-nationality .PhoneInput .PhoneInputCountryIconImg {
  border-radius: 0.5em !important;
  border: none;
}

.requests-table .cell-nationality .PhoneInput input,
.requests-table .cell-nationality .PhoneInput .PhoneInputCountrySelectArrow {
  display: none;
}

.customer-popup {
  text-align: center;
}

.customer-popup .modal-body {
  min-height: 25rem;
  padding: 0rem;
}

.customer-popup .customer-section {
  padding: 1rem;
  border-bottom: 1px solid rgba(0,0,0,.2);
}

.customer-popup .customer-subsection {
  display: inline-block;
  width: 50%;
}

.customer-popup .customer-subsection-mob .PhoneInput {
  margin: auto;
  width: 15rem;
}

.customer-popup .mobile-booking-row {
  font-size: 1.4rem;
}

.customer-popup .mobile-booking-row .origin-logo {
  display: inline-block;
  float: none;
  position: relative;
  top: -.2rem;
  width: 2.5rem;
}

.customer-popup i {
  font-size: 1.8rem;
  margin: auto;
}

.customer-popup .customer-subsection-mob {
  display: block;
  margin: auto;
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: center;
}

.customer-popup .instagram-logo {
  width: 3rem;
  margin-top: -0.6rem;
  margin-right: 0.5rem;
  margin-left: -2rem;
}

.customer-popup .instagram-handle {
  color: #495057;
  top: -.2rem;
  position: relative;
  display: inline-block;
  font-size: 1.2rem;
}

.customer-name {
  font-size: 2rem;
  padding-top: 1rem;
}

.customer-popup .customer-visits {
  display: flexbox;
  width: 90%;
  margin: auto;
  margin-top: 0.6rem;
}

.customer-popup .customer-visits td {
  padding: 0.3rem;
}

.customer-popup .customer-visits td:first-child {
  font-weight: bold;
}

.customer-popup .btn-close {
  font-size: 2rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: block;
  position: absolute;
  right: -1rem;
  top: -1rem;
  border: 1px solid rgba(0,0,0,.2);
  z-index: 1000;
}

.box-row .header-text,
.options-popup .header-text {
  font-size: 1.5rem;
}

.box-row i,
.options-popup .modal-header i {
  margin: 0.4rem 0.4rem 0rem 1rem;
}

.options-popup .modal-body {
  text-align: center;
}

.options-popup .modal-body button {
  width: 100%;
}

.options-popup .modal-content h3 {
  display: block;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.options-popup .modal-content .offer-times {
  margin-top: 0rem;
}

.options-popup .modal-content .col {
  min-width: 25%;
}

.options-popup .modal-content .form-check {
  padding: 0rem;
}

.options-popup .modal-content .tab-content {
  padding: 1rem 0rem;
}

.options-popup .modal-content input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.options-popup .modal-content label {
  margin: 0rem .3rem;
  background-color: whitesmoke;
  border-radius: 0.3rem;
}

.options-popup .modal-content input:checked ~ label {
  margin: 0rem .3rem;
  background-color: #007bff;
  color: white;
}

.customer-table .PhoneInput .PhoneInputCountryIcon--border {
  background-color: none !important;
  box-shadow: none !important;
}

.customer-table .PhoneInput .PhoneInputCountryIconImg {
  margin-left: 0em !important;
  margin-right: 1em !important;
  border: none !important;
  border-radius: 0.5em;
}

.customer-table .PhoneInput .PhoneInputCountrySelectArrow {
  display: none;
}

.customer-table .PhoneInput .PhoneInputInput {
  margin-left: 1.2rem;
  border: none !important;
  background: none !important;
}

.customer-table {
  text-align: center;
}

.customer-table td {
  white-space: nowrap;
}

.customer-table tr:hover {
  cursor: pointer;
  background-color: rgba(0,123,255,0.5) !important;
  transition: background-color 0.25s linear;
}

.customer-table thead tr:hover {
  background-color: white !important;
}

.filter-form {
  display: inline-block;
  margin-left: 0.5rem;
  width: 50%;
}

.filter-form::placeholder {
  font-style: italic;
}

.stubby-input, .super-stubby-input {
  display: inline-block;
  text-align: center;
}

.super-stubby-input {
  width: 3rem !important;
}

.stubby-input {
  width: 6rem;
}

.overview-page {
  overflow-x: scroll;
}

.overview-page h3 {
  float: left;
  margin: 0.8rem;
}

.mobile-table .box-row {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  background: linear-gradient(180deg, #ffffff 0%, #dee2e6 100%);
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid grey;
  overflow: hidden;
}

.mobile-table .box-row.unconfirmed {
  background: linear-gradient(180deg, #ffffff 0%, #faf9c4 100%);
}

.mobile-table .header-text {
  padding: .5rem 0;
  font-size: 1.5rem;
  border-bottom: 1px solid grey;
}

.mobile-table .box-body {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  border-bottom: 1px solid grey;
}

.mobile-table .box-body .PhoneInput {
  font-size: 1rem;
  margin-right: 1rem;
  clear: both;
  max-width: 15rem;
  margin: auto;
}

.mobile-table .box-body .additional-info {
  display: block;
  margin-top: 1rem;
  font-size: 1rem;
  font-style: italic;
}

.mobile-table .box-footer {
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  font-style: italic;
}

.mobile-table .box-footer .soft-label {
  margin-right: 1rem;
  color: grey;
}

.mobile-table .box-footer .barloader-container {
  display: block;
  width: 50%;
  float: left;
  margin: 0.7rem 5rem;
}

.mobile-table .box-footer .barloader-container div {
  width: 100%;
}

.mobile-table .box-footer .origin-logo {
  width: 2rem;
  margin-top: -0.2rem;
  margin-left: 0.5rem;
  display: inline;
  float: none;
}

.mobile-table .box-row .PhoneInputCountrySelectArrow {
  visibility: hidden;
}

.spacetime-container .date-navigator,
.overview-page .date-navigator {
  float: left;
  display: block;
  height: auto;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  margin-left: 1rem;
  margin-bottom: 0.8rem;
}

@media (max-width: 768px) {
  .spacetime-container .date-navigator{
    zoom: 0.6;
    margin-bottom: 3rem;
  }
}

.spacetime-container .date-navigator button,
.overview-page .date-navigator button {
  width: 4.5rem;
  height: 3.5rem;
  font-size: 2rem;
  padding-top: -2rem;
  line-height: 1rem;
}

.spacetime-container .react-datepicker__input-container input,
.overview-page .react-datepicker__input-container input {
  font-size: 1.5rem;
  background-color: rgba(0,123,255,0.25);
  width: 12rem;
  margin: 0rem -0.3rem;
}

.spacetime-container .react-datepicker__input-container input:hover,
.overview-page .react-datepicker__input-container input:hover {
  background-color: rgba(0,123,255,0.5);
}

.settings-page h3 {
  text-align: center;
}

.settings-page .stripe-account-connected {
  text-align: center;
  display: inline-block;
  background-color: #7a73ff;
  padding: .5rem 1rem;
  border-radius: 0.3rem;
  color: white;
}

.settings-page .stripe-account-connected:hover {
  background-color: #635bff;
  cursor: pointer;
  text-decoration: none;
}

.settings-page .tier-bee {
  position: absolute;
  width: 8rem;
  top: -6rem;
  z-index: 10;
}

.settings-page .align-center {
  text-align: center;
}

.settings-page .SMS-infobox {
  display: block;
  width: 90%;
  margin: auto;
  margin-bottom: 4rem;
}

.settings-page .card {
  display: inline-flex;
  margin: 0.5rem;
  min-width: 8rem;
  width: 22%;
  text-align: center;
  border-top: 0.2rem solid rgb(0,123,255);
}

.settings-page .card .list-group-item:nth-child(1) {
}

.settings-page .card .list-group-item:nth-child(2) {
  font-size: 1.5rem;
}

.settings-page .card .list-group-item:nth-child(3) {
  font-style: italic;
}

.settings-page .dummy-booking {
  display: block;
  background-color: whitesmoke;
  margin: auto;
  padding: 1rem;
}

.settings-page .tab-content {
  border: 1px solid #dee2e6;
  border-radius: 0rem 0rem .3rem .3rem;
  border-top: none;
}

.settings-page .weekdays-wrapper {
  border: none;
}

.settings-page .weekday-sittings.active.show {
  display: inline-flex;
}

.settings-page .weekday-opening-hours a {
  text-align: center;
}

.settings-page .button-container button {
  margin: 0rem 0.5rem;
}

.settings-page .update-settings {
  margin: 1rem auto;
  width: 10rem;
}

.settings-page .comms-mockup .comms-message {
  display: block;
  border-radius: 0rem 1rem 1rem 1rem;
  background-color: #007bff;
  padding: 1rem;
  margin: 0rem 1rem;
  margin-bottom: 1rem;
  color: white;
}

.settings-page .comms-mockup .comms-charcount-inlimit {
  display: block;
  margin: -0.8rem 1rem 0rem 0rem;
  float: right;
  font-size: 0.8rem;
  color: grey;
}

.settings-page .comms-mockup .comms-charcount-exceeded {
  display: block;
  margin: -0.8rem 1rem 0rem 0rem;
  float: right;
  font-size: 0.8rem;
  font-weight: bold;
  color: red;
}

.settings-page .comms-mockup label {
  padding: 0rem 1rem;
  margin-top: 1rem;
  font-weight: bold;
}

table thead th {
  vertical-align: middle !important;
}

.table td {
  vertical-align: middle;
}

.weekday-label {
  display: inline-flex;
  font-weight: bold;
  font-size: 1.2em;
  width: 7rem;
  text-align: right;
}

.settings-page .sittings {
  display: inline-flex;
}

.settings-page .sitting .form-control {
  width: 6rem;
}

.settings-page .sitting .form-control:nth-child(1) {
  width: 100%;
}

.settings-page .sitting {
  background-color: rgba(0, 123, 255, 0.1);
  border: 1px solid #ced4da;
  margin: 0rem 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  min-width: 18rem;
}

.settings-page .unsaved {
  background-color: whitesmoke;
}

.settings-page .sitting .sitting-name {
  margin-top: -2rem;
  text-align: center;
  font-weight: bold;
}

.settings-page .sitting .form-label {
  padding: 0.5rem;
  text-align: right;
}

.settings-page .maitred-table-plan {
  max-width: 40rem;
  text-align: center;
}

.settings-page .maitred-table-plan .col {
  margin: 0.2rem;
}

.settings-page .maitred-table-plan button {
  margin: 0.2rem;
  width: 10rem;
}

.settings-page .spacetime-toggle {
  min-width: 15rem !important;
  margin-bottom: 1rem;
  margin-left: -1rem;
  white-space: nowrap;
}

.pagination {
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: center;
}

.weekdays-form .form-group {
  background-color: whitesmoke;
}

.maitred-form-setup .form-label {
  padding: 0.5rem 1rem;
  text-align: right;
}

.maitred-form-setup .form-control {
  font-family: 'Courier', sans-serif;
}

i {
  margin-right: 1em;
  width: 1.2em;
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
